import { defineStore } from "pinia";
import { IProduct } from "@/struct/IProduct";

export const useCartStore = defineStore("cart", {
  state: () => ({
    items: [] as IProduct[],
  }),
  actions: {
    loadInitialData() {
      const storedItems = localStorage.getItem("cart-items");
      if (storedItems) {
        this.items = JSON.parse(storedItems);
      }
    },
    addItem(product: IProduct) {
      this.items.push(product);
      this.saveToLocalStorage();
    },
    removeItem(productId: string) {
      this.items = this.items.filter((item) => item.uuid !== productId);
      this.saveToLocalStorage();
    },
    clearCart() {
      this.items = [];
      localStorage.removeItem("cart-items");
    },
    saveToLocalStorage() {
      localStorage.setItem("cart-items", JSON.stringify(this.items));
    },
  },
});
