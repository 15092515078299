import { defineStore } from "pinia";
interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}
export const useInstallAppStore = defineStore("install", {
  state: () => ({
    deferredPromt: null as BeforeInstallPromptEvent | null,
    isInstalled: false,
  }),
  actions: {
    setData(value: any) {
      this.deferredPromt = value;
    },
    setIsInstalledValue(value: boolean) {
      this.isInstalled = value;
    },
  },
});
