<script setup lang="ts"></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="23"
    viewBox="0 0 30 23"
  >
    <path
      d="M14.3334 0.328645C13.7814 -0.0821612 13.0315 -0.107837 12.4431 0.251619C11.8546 0.611074 11.563 1.29917 11.6984 1.96673L12.2035 4.46238C11.1933 4.92454 10.282 5.48426 9.4748 6.07993C8.67806 6.67046 7.96464 7.30721 7.35537 7.94396L2.50722 5.15561C1.85629 4.78075 1.0283 4.88346 0.491935 5.40723C-0.0444328 5.93101 -0.153789 6.74235 0.221148 7.38937L2.60616 11.5026L0.221148 15.6158C-0.153789 16.2628 -0.0392253 17.0741 0.497143 17.5979C1.03351 18.1217 1.85629 18.2244 2.51243 17.8495L7.36057 15.0612C7.97505 15.6928 8.68327 16.3347 9.48001 16.9252C10.2872 17.5209 11.1985 18.0806 12.2087 18.5428L11.7036 21.0384C11.5682 21.706 11.865 22.3889 12.4483 22.7484C13.0315 23.1078 13.7814 23.0822 14.3386 22.6714L18.3796 19.6827C21.2385 19.4773 23.6651 18.2963 25.5242 16.9201C27.5655 15.4104 29.0236 13.5977 29.7735 12.3242C30.0755 11.8158 30.0755 11.1842 29.7735 10.6758C29.0236 9.40232 27.5603 7.58964 25.5242 6.07993C23.6599 4.70373 21.2385 3.52266 18.3796 3.31726L14.3334 0.328645ZM19.9991 11.5026C19.9991 11.0668 20.1746 10.6488 20.4872 10.3406C20.7997 10.0325 21.2235 9.85934 21.6655 9.85934C22.1074 9.85934 22.5313 10.0325 22.8438 10.3406C23.1563 10.6488 23.3319 11.0668 23.3319 11.5026C23.3319 11.9384 23.1563 12.3563 22.8438 12.6645C22.5313 12.9727 22.1074 13.1458 21.6655 13.1458C21.2235 13.1458 20.7997 12.9727 20.4872 12.6645C20.1746 12.3563 19.9991 11.9384 19.9991 11.5026Z"
    />
  </svg>
</template>
