import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import AboutView from "@/views/AboutView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "AboutView",
    component: AboutView,
    meta: {
      layout: true,
    },
  },
  {
    path: "/profile",
    name: "ProfilePage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProfilePage.vue"),
    meta: {
      layout: true,
    },
  },
  {
    path: "/android",
    name: "AndroidStore",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Stores/AndroidStore.vue"
      ),
    meta: {
      layout: false,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
