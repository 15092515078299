import { createApp } from "vue";
import AppMain from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./assets/index.css";
import "vue3-toastify/dist/index.css";

import { createPinia } from "pinia";
const pinia = createPinia();
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import "vue3-openlayers/styles.css";
import OpenLayersMap, {
  type Vue3OpenlayersGlobalOptions,
} from "vue3-openlayers";
// ...

const options: Vue3OpenlayersGlobalOptions = {
  debug: true,
};

const app = createApp(AppMain);
const isPWAMode =
  window.matchMedia("(display-mode: standalone)").matches ||
  (window.navigator as any).standalone ||
  document.referrer.includes("android-app://");

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

app.config.globalProperties.$IS_SAFARI = isSafari;
app.config.globalProperties.$IS_PWA = isPWAMode;

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("sw.js")
      .then(() => {
        console.log("Service worker registered success");
      })
      .catch((error) => {
        console.log("Service worker registration failed:", error);
      });
  });
}

app.use(router);
app.use(pinia);
app.use(OpenLayersMap, options);
app.use(autoAnimatePlugin);
app.mount("#app");
